@import "../../sass/vars"

.form-wrap
  width: 100%

.contactus-form
  display: flex
  flex-wrap: wrap

  & > div
    flex: 50%
    padding: 10px 17px
    display: flex
    flex-direction: column
    align-items: center

    &:nth-last-child(2)
      flex: 100%

    &:nth-last-child(1)
      flex: 300px 0

    @media (max-width: 800px)
      flex: 100%


    &.note
      font: normal normal 300 24px/32px Sofia Pro

    .error
      width: 100%
      color: red
      font: normal normal 300 18px/32px Sofia Pro
      text-align: right
      margin-right: 50px

