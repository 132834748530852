@import "../../sass/vars"

.input-text
  width: 100%

  input, .select__control, textarea
    width: 100%
    border-radius: 50px
    border: 2px solid $peri_pale
    font: normal normal 300 29px/34px Sofia Pro
    padding: 22px 35px
    color: $black
    background-color: #ffffff
    transition: background 0.3s ease

    @media (max-width: 1200px)
      font-size: 16px
      padding: 8px 15px
      border-radius: 25px

    &::placeholder
      color: $black
      opacity: 0.5

    &:hover, &:focus
      border-color: $peach_pastel

  .select__value-container
    padding: 0

  .select__control--is-focused
    box-shadow: none !important

  .select__indicator-separator
    display: none

  .select__input-container,.select__single-value
    margin: 0
    color: $black

  .select__menu
    font: normal normal 300 29px/34px Sofia Pro
    @media (max-width: 768px)
      font-size: 16px

  .react-datepicker
    color: $black
    border-color: $peri_pale

    .react-datepicker__header
      background-color: $yellow