@font-face
  font-family: 'Sofia Pro'
  src: local("Sofia Pro"), local("SofiaProBold"),
  src: url("../fonts/SofiaProBold/SofiaProBold.woff") format("woff"),
  src: url("../fonts/SofiaProBold/SofiaProBold.woff2") format("woff2")
  font-style: normal
  font-weight: 800
  text-rendering: optimizeLegibility


@font-face
  font-family: 'Sofia Pro'
  src: local("Sofia Pro"), local("SofiaProSemiBold"),
  src: url("../fonts/SofiaProSemiBold/SofiaProSemiBold.woff") format("woff"),
  src: url("../fonts/SofiaProSemiBold/SofiaProSemiBold.woff2") format("woff2")
  font-style: normal
  font-weight: 600
  text-rendering: optimizeLegibility


@font-face
  font-family: 'Sofia Pro'
  src: local("Sofia Pro"), local("SofiaProRegular"),
  src: url("../fonts/SofiaProRegular/SofiaProRegular.woff") format("woff"),
  src: url("../fonts/SofiaProRegular/SofiaProRegular.woff2") format("woff2")
  font-style: normal
  font-weight: 400
  text-rendering: optimizeLegibility



@font-face
  font-family: 'Sofia Pro'
  src: local("Sofia Pro"), local("SofiaProLight"),
  src: url("../fonts/SofiaProLight/SofiaProLight.woff") format("woff"),
  src: url("../fonts/SofiaProLight/SofiaProLight.woff2") format("woff2")
  font-style: normal
  font-weight: 300
  text-rendering: optimizeLegibility
