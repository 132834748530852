@import "../../sass/vars"

.alert
  width: 50%
  margin: 20px auto
  padding: 30px
  position: relative
  border-radius: 5px
  box-shadow: 0 0 15px 5px #ccc

.close
  position: absolute
  width: 30px
  height: 30px
  opacity: 0.5
  border-width: 1px
  border-style: solid
  border-radius: 50%
  right: 15px
  top: 25px
  text-align: center
  font-size: 1.4em
  cursor: pointer

@mixin alert($name,$bgColor)
  $accentColor: darken($bgColor,50)
  .#{$name}
    background-color: #{$bgColor}
    border-left: 5px solid $accentColor
    .close
      border-color: $accentColor
      color: $accentColor

@include alert(simple-alert,#ebebeb)
@include alert(success-alert, $light-green)
@include alert(danger-alert,#f7a7a3)
@include alert(warning-alert,#ffd48a)