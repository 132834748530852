//fonts
$sofia: 'Sofia Pro', sans-serif


//colors
$peri_bold: #AABBDA
$peri_pastel: #C6D5ED
$peri_pale: #D3DEF1

$peach_bold: #F5D49E
$peach_pastel: #F9E4B7
$peach_pale: #F9E3C3

$yellow: #FDF4E7
$middle-yellow: #FDF4E7
$light-yellow: #FFF8E3

$green: #CAD7A4
$light-green: #F8F7E5

$black: #0D0D12
$grey: #F7F7F7
$white: #FFF

$shadow: 0 3px 6px rgba(0,0,0,0.16)
