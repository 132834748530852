@import "../../sass/vars"

.btn-submit
  width: 100%
  border: none
  background-color: $peri_pale
  box-shadow: $shadow
  border-radius: 50px
  min-height: 34px
  color: $black
  font: normal normal bold 29px/34px Sofia Pro
  padding: 24px 15px
  transition: background 0.3s ease

  @media (max-width: 1200px)
    font-size: 16px
    padding: 8px 15px
    border-radius: 25px

  &:hover, &:focus
    background-color: $peach_pastel
    cursor: pointer

  &:disabled
    background-color: #E4E4E4